import React from 'react';
import products_banner from '../img/products_banner.jpg';
import sov_product from '../img/sov_product.jpg';
import {Helmet} from "react-helmet";

function Sov() {
  
  return (
    <>
       <Helmet>
        <title>SOV Panel- Best electrical control panel manufacturer in Indore</title>
        <meta name="description" content="New Tech Power Industries Manufacturing and installation of SOV Panel in Indore, Madhya Pradesh that is Panel with fixing and wiring of solenoid operating valves as per electrical and pneumatic requirement." />
        <meta name="keywords" content="SOV Panel in India, SOV Panel in Indore, SOV Panel in Madhya Pradesh, SOV Panel in Indore, SOV Panel manufacturer supplier and distributor in Indore,  " />
    </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>SOV Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and installation of SOV Panel in Indore, Madhya Pradesh that is Panel with fixing and wiring of solenoid operating valves as per electrical and pneumatic requirement. These are manufactured by using quality material and environment friendly processing techniques.<br/>By using this panel customer can use his electrical signal for pneumatic applications. New Tech Power Industries customize our products as per the exact need of the client without compromising on the quality of the product.</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={sov_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Sov