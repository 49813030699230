
import './App.css';
import Header from './components/Header';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Index from './components/Index';
import About from './components/About';
import Contact from './components/Contact';
import Ppc from './components/Ppc';
import Apfc from './components/Apfc';
import Mcc from './components/Mcc';
import Power from './components/Power';
import Vfd from './components/Vfd';
import Sov from './components/Sov';
import Plc from './components/Plc';
import Mes from './components/Mes';
import Instrument from './components/Instrument';
import Gwssb from './components/Gwssb';
import Dg from './components/Dg';
import Amf from './components/Amf';
import Bus from './components/Bus';
import Clients from './components/Clients';
import Certificates from './components/Certificates';
import Data from './components/Data';
import Footer from './components/Footer';
import Scrollup from './components/Scrollup';
import Solar from './components/Solar';
import Fire from './components/Fire';





function App() {
  return (
    <>
      
        
        <Router>
          <Header/>
          <Routes>
            <Route index element={<Index />} />
            <Route path="/about" element={<About />} />
            <Route path="/ppc" element={<Ppc />} />
            <Route path="/mcc" element={<Mcc/>} />
            <Route path="/apfc" element={<Apfc />} />
            <Route path="/power" element={<Power/>} />
            <Route path="/vfd" element={<Vfd/>} />
            <Route path="/plc" element={<Plc />} />
            <Route path="/instrument" element={<Instrument />} />
            <Route path="/sov" element={<Sov />} />
            <Route path="/amf" element={<Amf />} />
            <Route path="/dg" element={<Dg/>} />
            <Route path="/bus" element={<Bus/>} />
            <Route path="/gwssb" element={<Gwssb/>} />
            <Route path="/mes" element={<Mes/>} />
            <Route path="/clients" element={<Clients/>} />
            <Route path="/certificates" element={<Certificates/>} />
            <Route path="/data" element={<Data/>} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/solar" element={<Solar/>} />
            <Route path="/fire" element={<Fire/>} />
            </Routes>
            <Footer/>
            <Scrollup/>
            
        </Router>
     
    </>
  );
}

export default App;
