import React from "react";
import Banner1 from "../img/banner1.jpg";
import Banner2 from "../img/banner2.jpg";
import Certificate from "../img/certificate.jpg";
import wlcm_img from "../img/wlcm_img.jpg";

import ppc from "../img/ppc.jpg";
import mcc from "../img/mcc.jpg";
import apfc from "../img/apfc.jpg";
import power from "../img/power.jpg";
import vfd from "../img/vfd.jpg";
import plc from "../img/plc.jpg";

import line from "../img/line.png";
import strength from "../img/strength.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FaCheckSquare } from "react-icons/fa";
import Uddyam from "../pdf/Uddyam Aadhar Scan.pdf";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import cer1 from "../img/cer1.png";
import cer2 from "../img/cer2.jpg";
import cer3 from "../img/cer3.png";
import cer4 from "../img/cer4.png";
import cer5 from "../img/cer5.png";

function Index() {
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <>
      <Helmet>
        <title>New Tech Power Industries</title>
        <meta
          name="description"
          content="New Tech Power Industries is successfully working since last 15 years in industrial sector. We are working in manufacturing, supplies and distribution as per requirement of industries."
        />
        <meta
          name="keywords"
          content="Electrical Control Panel in India, Electrical Control Panel in Indore, Electrical Control Panel in Madhya Pradesh, Solar Panel in Indore, Solar Panel in Madhya Pradesh, Electrical Panel, Solar Panel, Fire Safety panel, Manufacturing Company, Manufacturers, Design, Electrical panel in India, Electrical Control Panel ,supplier and distributor in India, Electrical Control Panel manufacturer,supplier and distributor in Indore, Electrical Control Panel manufacturer,supplier and distributor in Madhya Pradesh, Solar Panel manufacturer,supplier and distributor in Indore, Solar Panel manufacturer,supplier and distributor in Madhya Pradesh, Fire Safety panel manufacturer,supplier and distributor in Madhya Pradesh, manufacturer,supplier and distributor in Indore,"
        />
      </Helmet>
      <div className="Main">
        {/*---------Slider start---------*/}
        <div
          id="carouselExampleAutoplaying"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={Banner2}
                className="d-block w-100"
                alt="Electrical Control Panel Banner"
              />
            </div>
            <div className="carousel-item">
              <img
                src={Banner1}
                className="d-block w-100"
                alt="Electrical Control Panel Banner"
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        {/*---------Slider End------- */}
        {/*---------About Start------- */}
        <div className="container welcome">
          <div className="row">
            <div className="col-md-6 col-12">
              <h2 className="ui-subtitle-block" style={{ color: "#ed3338" }}>
                Welcome to New Tech Power Industries
              </h2>
              <p className="ui-title-block">
                Quality Ensuring High Performance
              </p>
            </div>
            <div className="col-md-6 col-12">
              <img
                src={Certificate}
                className="img-responsive w-100"
                alt="Certificates"
              />
            </div>
            <div className="border-color"></div>
            <div className="col-md-6 col-12">
              <img
                src={wlcm_img}
                className="img-responsive w-100"
                alt="Electrical panel"
              />
            </div>
            <div className="col-md-6 col-12">
              <h1 style={{ color: "#ed3338" }}>New Tech Power Industries</h1>
              <p className="para">
                <strong>New Tech Power Industries </strong>manufacture, supply
                and distibute range of electrical control panel in India,
                Indore, Madhya Pradesh. New Tech Power Industries is
                successfully working since last 15 years in industrial sector.
                New Tech Power Industries is working in manufacturing, supplies
                and distribution as per requirement of industries. We keep in
                mind the requirement of the industry. As per the requirement of
                the industries we authorized the top brands. New Tech Power
                Industries promises to provide the best service for the brand
                products.
              </p>
              <Link className="Button" to="/about">
                Read More
              </Link>
            </div>
          </div>
        </div>
        {/*---------About End------- */}

        {/*---------Products-list Start------- */}
        <div className="products">
          <div className="container">
            <h2 className="text-center">Our Products</h2>
            <div className="linebottom text-center">
              <img src={line} data-no-retina="" alt="Line" />
            </div>
            <OwlCarousel
              className="owl-theme"
              loop
              margin={10}
              responsive={state.responsive}
              nav
            >
              <div className=" item mx-2">
                <div className="card">
                  <img
                    src={ppc}
                    className="card-img-top"
                    alt="Electrical panel Products"
                  />
                  <div className="card-body">
                    <h5 className="card-title">PCC Panel</h5>
                    <p className="card-text">
                      New Tech Power Industries offer PCC Panel in Indore, M.P,
                      India with a current capacity up to 6300Amp...
                    </p>
                    <Link to="/ppc" className="Button">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="  item mx-2">
                <div className="card">
                  <img
                    src={mcc}
                    className="card-img-top"
                    alt="Electrical panel Products"
                  />
                  <div className="card-body">
                    <h5 className="card-title">MCC Panel</h5>
                    <p className="card-text ">
                      New Tech Power Industries offer MCC Panels in India,
                      Indore, Madhya Pradesh. A device that is...
                    </p>
                    <Link to="/mcc" className="Button">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="  item mx-2">
                <div className="card">
                  <img
                    src={apfc}
                    className="card-img-top"
                    alt="Electrical panel Products"
                  />
                  <div className="card-body">
                    <h5 className="card-title">APFC Panel</h5>
                    <p className="card-text ">
                      New Tech Power Industries offer APFC Panel in India,
                      Indore, Madhya Pradehup to 1000KVA...
                    </p>
                    <Link to="/apfc" className="Button">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className=" item mx-2">
                <div className="card">
                  <img
                    src={power}
                    className="card-img-top"
                    alt="Electrical panel Products"
                  />
                  <div className="card-body">
                    <h5 className="card-title">Power Distribution Panel</h5>
                    <p className="card-text ">
                      The Power Distribution Panel can be availed with MCCB /
                      SFU / MCB...
                    </p>
                    <Link to="/power" className="Button">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className=" item mx-2">
                <div className="card">
                  <img
                    src={vfd}
                    className="card-img-top"
                    alt="Electrical panel Products"
                  />
                  <div className="card-body">
                    <h5 className="card-title">VFD Control Panel</h5>
                    <p className="card-text ">
                      Our range of VF Drive Control Panel is a speed drive that
                      provides a means of driving...
                    </p>
                    <Link to="/vfd" className="Button">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="item mx-2">
                <div className="card">
                  <img
                    src={plc}
                    className="card-img-top"
                    alt="Electrical panel Products"
                  />
                  <div className="card-body">
                    <h5 className="card-title ">PLC Control panel</h5>
                    <p className="card-text ">
                      New Tech Power Industries manufacture PLC Panel in India,
                      Indore, Madhya Pradesh...
                    </p>
                    <Link to="/plc" className="Button">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
        {/*---------Products-list End------- */}
        {/*---------testimonail Start------- */}
        <div className="container strngth">
          <div className="row">
            <div className="col-md-6 c0l-12 Strengths">
              <h2>Our Strengths</h2>
              <p>
                As a rapidly growing organization, New Tech Power Industries
                constantly seek to achieve excellence in every activity that is
                undertaken. Some of the stand out features of the organization,
                which propels it ahead of its competitors, is as follows:
              </p>
              <ul>
                <li>
                  <FaCheckSquare />
                  System base professional Organisation
                </li>
                <li>
                  <FaCheckSquare />
                  Only qualified and authorized personnel are engaged to ensure
                  safety.{" "}
                </li>
                <li>
                  <FaCheckSquare />
                  On time delivery of product
                </li>
                <li>
                  <FaCheckSquare />
                  Best quality products by following ISO standards
                </li>
              </ul>
            </div>

            <div className="col-md-6 col-12">
              <img src={strength} className="img-responsive w-100" alt="Line" />
            </div>
          </div>
          {/*---------testimonail End------- */}
        </div>
        {/*---------Contact------- */}
        <div className="home_cnt">
          <div className="container my-5">
            <div className="row">
              <div
                className="col-8 about-left text-left"
                style={{ marginTop: "20px" }}
              >
                <h4>GET IN TOUCH</h4>
                <p>
                  New Tech Power Industries deliver innovative customised
                  solutions as per the requirements.
                </p>
              </div>
              <div className="col-4 contact_button">
                <Link to="/contact" className="Button">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*---------Contact End------- */}
        {/*---------Certificate------- */}
        <h2 className="text-center py-3">Certificates</h2>
        <div className="linebottom text-center">
          <img src={line} data-no-retina="" alt="Line" />
        </div>
        <div
          className="container d-flex justify-content-center certificates"
          style={{ marginBottom: "50px" }}
        >
          <div className=" row d-flex justify-content-center ">
            <div className="col-md-2 col-12 my-2 d-flex justify-content-center">
              <img src={cer2} className="" alt="Certificates" />
            </div>
            <div className="col-md-2 col-12 my-2 d-flex justify-content-center">
              <img src={cer4} className="img1" alt="Certificates" />
            </div>
            <div className="col-md-2 col-12 my-2 d-flex justify-content-center">
              <img src={cer1} className="img1" alt="Certificates" />
            </div>
            <div className="col-md-2 col-12 my-2 d-flex justify-content-center">
              <a href={Uddyam} target="_blank" rel="noreferrer">
                <img src={cer3} className="" alt="Certificates" />
              </a>
            </div>
            <div className="col-md-2 col-12 my-2 d-flex justify-content-center">
              <a href={Uddyam} target="_blank" rel="noreferrer">
                <img src={cer5} className="" alt="Certificates" />
              </a>
            </div>
          </div>
        </div>

        {/*---------Certificate End------- */}
      </div>
    </>
  );
}

export default Index;
