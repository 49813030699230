import React from 'react';
import products_banner from '../img/products_banner.jpg';
import bus_product from '../img/bus_product.jpg';

function Bus() {
  document.title='Bus Duct- Best electrical control panel manufacturer in Indore'
  return (
    <>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>Bus Duct</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries have product range of Bus Duct in India, complete with CRCA / Aluminum enclosures for ensuring their safety from any wear tear. New Tech Power Industries hold the expertise in design, engineering, &amp; manufacturing of various types of bus ducts panel with a range up to 6300Amp. <br/>Bus Duct Control Panel in India, Indore, Madhya Pradesh with Heat Shrinkable Sleeves and well supported on SMC / DMC / FRP supports. New Tech Power Industries can offer ingress protection IP-54/IP-55/IP-65. New Tech Power Industries also provide flexible jumpers if required from customer.</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={bus_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Bus