import React from 'react';
import products_banner from '../img/products_banner.jpg';
import vfd_product from '../img/vfd_product.jpg';
import {Helmet} from "react-helmet";

function Vfd() {
  
  return (
    <>
     <Helmet>
        <title>VFD Control Panel- Best electrical control panel manufacturer in Indore</title>
        <meta name="description" content="New Tech Power Industries Manufacturing and installation all range of VF Drive Control Panel is a speed drive that provides a means of driving and adjusting the operating speed of Motor." />
        <meta name="keywords" content="VFD Control Panel Panel in India, VFD Control Panel Panel in Indore, VFD Control Panel Panel in Madhya Pradesh, VFD Control Panel Panel in Indore, VFD Control Panel Panel manufacturer supplier and distributor in Indore, " />
    </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>VFD Control Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and installation all range of VF Drive Control Panel is a speed drive that provides a means of driving and adjusting the operating speed of Motor.<br/> New Tech Power Industries offer VF Drive Control Panel  fully equipped with all the necessary switch gears like MCCB, Bypass Contactor, Input / Output Chock, Speed Control Pot etc. As special features, New Tech Power Industries can provide VFD Panel with DOL / Star Delta starter so that customer can run his motor on DOL/SD at the time of Breakdown of VFD.</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={vfd_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Vfd