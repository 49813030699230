import React from 'react';
import { Helmet } from "react-helmet";
import fire_banner from '../img/fire_banner.jpg';
import fire from '../img/fire.jpg';
import foam from '../img/foam.png';
import water from '../img/water.png';
import dry from '../img/dry.png';
import hydrant from '../img/hydrant.jpg';
import supersion from '../img/supersion.jpg';
import pdf_download from '../img/pdf_download.png';
import control from '../img/fire control panel.jpg';


function Fire() {
    return (
        <>
             <Helmet>
                <title>Fire Extinguisher- Top Fire Extinguisher distributor in Indore</title>
                <meta name="description" content="New Tech Power Industries Deal in All Type Types and Classes Fire Extinguisher. The six main fire extinguisher types are water, foam, CO2, powder, water mist and wet chemical. Each of the different types of fire extinguisher is suitable for different fire classes. It is important that you purchase the right fire extinguisher for Industry’s needs." />
                <meta name="keywords" content="Fire Extinguisher in India, Fire Extinguisher in Indore, Fire Extinguisher in Madhya Pradesh, Fire Extinguisher in Indore, Fire Extinguisher  supplier and distributor in Indore," />
            </Helmet>
            {/*---------Banner Start------- */}
            <div className="Page_banner">
                <img src={fire_banner} alt="fire_banner" />
            </div>
            {/*---------Banner End------- */}
            <div className="container product_description my-5">
                <p className=" text-justify mb-5" style={{ fontSize: "18px", textAlign: "justify" }}><strong>New Tech Power Industries</strong> deal in Fire Extinguisher, Fire Alarm, Fire Hydrant, Fire Suppression System, Sales Maintenance Training and audit of Fire safety equipment in industries, Madhya Pradesh, India. New Tech Power Industries is Sales All type of Fire Extinguisher as per requirement of industries. We keep in mind the requirement of the industry safety. As per the requirement of the industries, we authorized the top brands. New Tech Power Industries promises to provide the best service for the brand products and Services.</p>
                <div className="row">
                    <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

                        <h2 style={{ color: "#ed3338" }}>Fire Extinguisher</h2>
                        <p className="para text-justify" style={{ textAlign: "justify", padding: "0px 20px 0px 0px" }}>A fire extinguisher is a handheld active fire protection device usually filled with a dry or wet chemical used to extinguish or control small fires, often in emergencies. It is not intended for use on an out-of-control fire, such as one which has reached the ceiling, endangers the user (i.e., no escape route, smoke, explosion hazard, etc.), or otherwise requires the equipment, personnel, resources, and/or expertise of a fire brigade. Typically, a fire extinguisher consists of a hand-held cylindrical pressure vessel containing an agent that can be discharged to extinguish a fire. <br></br>There are two main types of fire extinguishers: stored-pressure and cartridge-operated. In stored pressure units, the expellant is stored in the same chamber as the firefighting agent itself.Cartridge-operated extinguishers contain the expellant gas in a separate cartridge that is punctured before discharge, exposing the propellant to the extinguishing agent. </p>
                    </div>
                    <div className="col-md-6 col-12">

                        <img src={fire} className="img-responsive w-100" alt="Fire Extinguisher" />
                    </div>



                </div>


                <div className="fireType_details my-5">
                    <div className="row">
                        <div className="col-md-6 col-12">

                            <img src={control} className="img-responsive w-100" alt="Fire Alarm System" />
                        </div>
                        <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

                            <h3 style={{ color: "#ed3338" }}>Fire Alarm System</h3>
                            <p className="para text-justify" style={{ textAlign: "justify", padding: "0px 20px 0px 0px" }}>In the simplest terms, the role of a fire alarm system is to detect fires and alert both building occupants and emergency personnel from a centrally monitored and controlled location.
                                These systems also self-monitor, identifying where within the building(s) alarms originate from and detecting when errors occur in wiring and connections that may hinder the system from working correctly.
                                In essence, a fire alarm system has four key functions: detect, alert, monitor, and control.<br></br>The fire alarm panel is connected to the system’s initiating devices through either 2- or 4-wire circuits. This circuitry allows the control panel to monitor the state of its initiating devices, usually by zones, identifying whether the devices are in normal or alarm mode. The control panel shows these readings on its display panel.
                                When a fire starts, the smoke or heat will activate one of the initiating devices, or someone will activate the manual pull station, alerting the fire alarm system to the fire and putting it in alarm mode.
                            </p>




                        </div>


                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

                        <h2 style={{ color: "#ed3338" }}>Fire Hydrant Control Panel</h2>
                        <p className="para text-justify" style={{ textAlign: "justify", padding: "0px 20px 0px 0px" }}>Being one of the oldest but yet the most effective and common fire fighting solution, a well designed and a well laid out Hydrant System forms the backbone of the entire fire fighting system. It comprises of heavy duty above & underground piping with accessories. External and Fire Escape Hydrant valves are provided at every strategic location. A fire hydrant is a pipe that allows water to flow from a water main with the control of a valve in order to put out a fire.<br></br><br></br>This Panel with DOL, STAR-DELTA, ATS, SOFT STARTER, VFD starters. Provision of Main Pump, Jockey Pump, Sprinkler Pump, Booster Pump, Engine Driven Pump. Automation with Pressure switch. Facilities of different control voltage with control transformer control Bus. With type-2 co-ordination wherever required.</p>
                    </div>
                    <div className="col-md-6 col-12">

                        <img src={hydrant} className="img-responsive w-100" alt="Fire Extinguisher" />
                    </div>



                </div>
                <div className="row my-5">
                    <div className="col-md-6 col-12">

                        <img src={supersion} className="img-responsive w-100" alt="Fire Extinguisher" style={{ border: "none" }} />
                    </div>
                    <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

                        <h2 style={{ color: "#ed3338" }}>Fire Suppression System</h2>
                        <p className="para text-justify" style={{ textAlign: "justify", padding: "0px 20px 0px 0px" }}>A fire suppression system is an engineered unit created for extinguishing fires. More often than not, a fire suppression system comes with components that can detect fire in its nascent stages.
                            A fire suppression system usually extinguishes the flames through heat absorption and oxygen depletion. In order to minimize the damage and losses from fire, a fire suppression system might be used.
                            <br></br>The suppression system detects the flames through smoke, heat, and other warning signals. The parts of the suppression systems are often integrated into an alarm system, which gets triggered when the fire is detected. This trigger also initiates the primary steps that help to suppress the fire further. Most suppression systems release the fire extinguishing components automatically once the alarm is triggered. However, other systems require manual intervention.</p>
                    </div>




                </div>
            </div>

        </>
    )
}

export default Fire