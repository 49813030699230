import React from 'react';
import products_banner from '../img/products_banner.jpg';
import mcc_product from '../img/mcc_product.jpg';
import {Helmet} from "react-helmet";

function Mcc() {
  
  return (
    <>
     <Helmet>
        <title>MCC Panel- Best electrical control panel manufacturer in Indore</title>
        <meta name="description" content="New Tech Power Industries Manufacturing and Installation off MCC Panels in India, Indore, Madhya Pradesh. A device that is used to control and actualize the performance of an electric motor." />
        <meta name="keywords" content="MCC Control Panel Panel in India, MCC Control Panel Panel in Indore, MCC Control Panel Panel in Madhya Pradesh, MCC Control Panel Panel in Indore, MCC Control Panel Panel manufacturer supplier and distributor in Indore," />
    </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>MCC Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and Installation off MCC Panels in India, Indore, Madhya Pradesh. A device that is used to control and actualize the performance of an electric motor. These are offered in a wide variety such as fully draw out / Non-draw out type / Compartmentalized / Non compartmentalized / Single Front / Double front operated. It is equipped with ACB / MCCB/ SFU/MCB, DOL, ATS or Star Delta Starters for automatic/ manual means of starting and stopping the motor. New Tech Power Industries provide facility to operate from Local/Remote / DCS from Motor Control Center.<br/> Common Protections provided in are Over Load, Short Circuit, Single Phasing etc and special features New Tech Power Industries can add are Earth Fault, Reverser Power, under Load, over and under Voltage etc for Motor Control Center in India, Indore, and Madhya Pradesh. New Tech Power Industries also offer intelligent MCC Panel in Indore, Madhya Pradesh that can communicate with PLC, can be start and stop from SCADA; you can measure its power parameters from control room.</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={mcc_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Mcc