import React from 'react';
import client_banner from '../img/client_banner.jpg';
import cer1 from '../img/cer1.jpg';
import cer2 from '../img/cer2.jpg';
import line from '../img/line.png';
import Uddyam from '../pdf/Uddyam Aadhar Scan.pdf';

function Certificates() {
  document.title='Certificates- New Tech Power Industries'
  return (
    <>
    <div className="Page_banner">
        <img src={client_banner} alt="Client_banner" />
      </div>
    {/*---------Certificate------- */}
    <h2 className="text-center py-3">Certificates</h2>
    <div className="linebottom text-center">
      <img src={line} data-no-retina="" />
    </div>
    <div className="container d-flex justify-content-center certificates" style={{ marginBottom: "50px" }}>

      <div className="col-md-6 col-12 row ">
        <div className="col-md-6 col-6 my-2 ">
          <img src={cer1} className="img1" alt="Certificates" />
        </div>
        <div className="col-md-6 col-6 my-2 ">
          <a href={Uddyam} target="_blank" rel="noreferrer"><img src={cer2} className="" alt="Certificates" /></a>
        </div>

      </div>
    </div>

    {/*---------Certificate End------- */}
    </>
  )
}

export default Certificates