import React from 'react';
import products_banner from '../img/products_banner.jpg';
import power_product from '../img/power_product.jpg';
import {Helmet} from "react-helmet";

function Power() {
  
  return (
    <>
     <Helmet>
        <title>Power Distribution Panel- Best electrical control panel manufacturer in Indore</title>
        <meta name="description" content="New Tech Power Industries Manufacturing and Installation off Power Distribution Panel It can be availed with MCCB / SFU / MCB fully equipped with all necessary safety precautions." />
        <meta name="keywords" content="Power distribution Control Panel Panel in India, Power distribution Control Panel Panel in Indore, Power distribution Control Panel Panel in Madhya Pradesh, Power distribution Control Panel Panel in Indore, Power distribution Control Panel Panel manufacturer supplier and distributor in Indore, " />
    </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>Power Distribution Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and Installation off Power Distribution Panel It can be availed with MCCB / SFU / MCB fully equipped with all necessary safety precautions. New Tech Power Industries offer Feeder Pillar Panels / Fuse DB / Bus bar Distribution Boards with Canopy and double Doors for Outdoor Applications.<br/> Our Power Distribution Panel in Indore and Power Distribution Panel in Madhya Pradesh, India are applicable for Residential buildings, Industrial buildings, Hotels, Stadiums, and Airports etc.</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={power_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Power