import React from "react";
import contact_banner from "../img/contact_banner.jpg";
import { Helmet } from "react-helmet";
import cnt from "../img/cnt.jpg";
import Hiring from "../img/hiring.png";
function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us- New Tech Power Industries</title>
        <meta
          name="description"
          content="New Tech Power Industries is successfully working since last 15 years in industrial sector. We are working in manufacturing, supplies and distribution as per requirement of industries."
        />
        <meta
          name="keywords"
          content="Electrical Control Panel in India, Electrical Control Panel in Indore, Electrical Control Panel in Madhya Pradesh, Solar Panel in Indore, Solar Panel in Madhya Pradesh, Electrical Panel, Solar Panel, Fire Safety panel, Manufacturing Company, Manufacturers, Design, Electrical panel in India, Electrical Control Panel ,supplier and distributor in India, Electrical Control Panel manufacturer,supplier and distributor in Indore, Electrical Control Panel manufacturer,supplier and distributor in Madhya Pradesh, Solar Panel manufacturer,supplier and distributor in Indore, Solar Panel manufacturer,supplier and distributor in Madhya Pradesh, Fire Safety panel manufacturer,supplier and distributor in Madhya Pradesh, manufacturer,supplier and distributor in Indore,"
        />
      </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={contact_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      {/*---------Contact Details Start------- */}
      <div className="container my-5">
        <div className="row">
          <div className="col-md-6 col-12 contact_form">
            <img src={cnt} className="img-responsive w-100" alt="Contact" />
          </div>
          <div className="col-md-6 col-12">
            <h2>Address</h2>
            <div className="contact_detils">
              <p style={{ fontSize: "18px" }}>
                <strong style={{ fontSize: "20px", color: "#ed3338" }}>
                  Corporate Office:
                </strong>{" "}
                27/2, Manoramagani, Indore (M.P) 452001
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong style={{ fontSize: "20px", color: "#ed3338" }}>
                  Pithampur Office:
                </strong>{" "}
                Industrial Area, Pithampur, (M.P)
                454775
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong style={{ fontSize: "20px", color: "#ed3338" }}>
                  Bhopal Office:
                </strong>{" "}
                32 Raj Samrat Nagar, Aayodhya Bypass, Bhopal (M.P) 462022
              </p>

              <p style={{ fontSize: "18px" }}>
                <strong style={{ fontSize: "20px", color: "#ed3338" }}>
                  Factory Office:
                </strong>{" "}
                135, Devguradiya, Nemawar Road, Industrial Area. Indore (M.P)
                452016
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong style={{ fontSize: "20px", color: "#ed3338" }}>
                  Email:
                </strong>{" "}
                newtechpowerindustries@gmail.com
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong style={{ fontSize: "20px", color: "#ed3338" }}>
                  Contact:
                </strong>
                +91 93010 12346
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong style={{ fontSize: "20px", color: "#ed3338" }}>
                  Factory:
                </strong>
                +918815984527
              </p>
              <p style={{ fontSize: "18px" }}>
                <strong style={{ fontSize: "20px", color: "#ed3338" }}>
                  Website:
                </strong>{" "}
                <span style={{ fontWeight: "bold" }}>www.ntpi.in</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <img src={Hiring} alt="" style={{ width: "100%" }} />
      </div>
      <br />
      <br />
      {/*---------Contact Details End------- */}
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3681.255143381967!2d75.93656041496152!3d22.6815439851269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDQwJzUzLjYiTiA3NcKwNTYnMTkuNSJF!5e0!3m2!1sen!2sin!4v1678694427006!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
}

export default Contact;
