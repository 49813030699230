import React from "react";
import {
  FaEnvelopeSquare,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPhoneSquare,
  FaTwitter,
} from "react-icons/fa";
import Logo from "../img/official-logo.png";
import { Link } from "react-router-dom";
import Brochure from "../pdf/Brochure.pdf";
import switches from "../pdf/List of Product.xlsx";

function Header() {
  return (
    <div className="header">
      <div className="header__top">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="slogan">Thanks for Visit Our Website</div>
            </div>
            <div className="col-sm-3">
              <div className="header__social">
                <ul className="social-links">
                  <li className="social-links__item">
                    <a className="social-links__link" href="#" target="_blank">
                      <FaTwitter />
                    </a>{" "}
                  </li>
                  <li className="social-links__item">
                    <a className="social-links__link" href="#" target="_blank">
                      <FaFacebook />
                    </a>{" "}
                  </li>
                  <li className="social-links__item">
                    <a className="social-links__link" href="#" target="_blank">
                      <FaInstagram />
                    </a>{" "}
                  </li>
                  <li className="social-links__item">
                    <a className="social-links__link" href="#" target="_blank">
                      <FaLinkedin />
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="header-contacts">
                <a href="#">
                  <FaPhoneSquare />
                  +91 9301012346
                </a>
                
                <a href="contact.html">
                  <FaEnvelopeSquare />
                  newtechpowerindustries@gmail.com
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom_header">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 ">
              <div className="header__logo">
                {" "}
                <Link to="/" className="logo">
                  {" "}
                  <img src={Logo} alt="Logo" />{" "}
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 menu">
              <nav className="navbar navbar-expand-lg ">
                <div className="container-fluid">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarTogglerDemo01"
                    aria-controls="navbarTogglerDemo01"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse py-2"
                    id="navbarTogglerDemo01"
                  >
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                      <li className="nav-item nav_btn">
                        <Link
                          className="nav-link active"
                          aria-current="page"
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
                      <li className="nav-item nav_btn">
                        <Link className="nav-link" to="/about">
                          About
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Products
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              href="#"
                              className="dropdown-item nav-link dropdown-toggle"
                              role="button"
                              data-bs-toggle="submenu"
                              aria-expanded="false"
                            >
                              Electrical Panel
                            </a>
                            <ul className="submenu dropdown-menu">
                              <li>
                                <Link className="dropdown-item" to="/ppc">
                                  PCC Panel
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/mcc">
                                  MCC Panel
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/apfc">
                                  APFC Panel
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/power">
                                  Power Distribution Panel
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/vfd">
                                  VFD Control Panel
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/plc">
                                  PLC Control Panel
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="dropdown-item"
                                  to="/instrument"
                                >
                                  Instrumentation Panel
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/sov">
                                  SOV Panel
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/amf">
                                  AMF Control Panel
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/dg">
                                  DG Synchronization Panel
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/bus">
                                  Bus Duct
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/gwssb">
                                  GWSSB Panel
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to="/mes">
                                  MES Panel
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="nav-item nav_btn">
                            <Link className="nav-link" to="/solar">
                              Solar Panel
                            </Link>
                          </li>
                          <li className="nav-item nav_btn">
                            <Link className="nav-link" to="/fire">
                              Fire Safety Equipments
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item nav_btn">
                        <Link className="nav-link" to="/clients">
                          Our Clients
                        </Link>
                      </li>
                      <li className="nav-item nav_btn">
                        <Link className="nav-link" to="/certificates">
                          Certificates
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Downloads
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              className="dropdown-item"
                              href={Brochure}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Company Brochure
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/*<li className="nav-item">
                                                <Link className="nav-link" to="#">Technical Data</Link>
                                            </li>*/}
                      <li className="nav-item nav_btn">
                        <Link className="nav-link" to="/contact">
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
