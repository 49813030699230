import React from 'react';
import products_banner from '../img/products_banner.jpg';
import mes_product from '../img/mes_product.jpg';
function Mes() {
  document.title='Mes Panel- Best electrical control panel manufacturer in Indore'
  return (
    <>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="Electrical Panel" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>Mes Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and installation are approved vendor of MPEB, PWD, and CPWD, Military Engineering Services, Madhya Pradesh Industrial Association, MSME for LT Panels, Bus duct and Junction Boxes. New Tech Power Industries can supply Double door with canopy Type Kiosk, Feeder Pillar Panels for Outdoor application in 3 mm thick CRCA sheet with necessary switchgears. New Tech Power Industries can supply LT Panels up to 4000A with Copper or Aluminum Bubars.
<br/>We have Type Test certificate for Short Circuit Test from CPRI, Bangalore.<br/>
We have Type Test certificate for IP-55 and IP-65 Protection from ERDA, Vadodara.
</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={mes_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Mes