import React from 'react'
import products_banner from '../img/products_banner.jpg';
import gwssb_product from '../img/gwssb_product.jpg';

function Gwssb() {
  document.title='GWSSB Panel- Best electrical control panel manufacturer in Indore'
  return (
    <>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>GWSSB Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and installation of GWSSB Panels<br/>
DOL Panel           : Up to 7.5 HP<br/>
Star Delta Panel: Up to 20 HP <br/>
ATS Panel            : Up to 60 HP<br/>	
PCC Panel            : Up to 2500A<br/>
MCC Panel          : Up to 2000A<br/>
APFC Panel         : Up to 500 KVAR<br/>
LDB Panel            : Up to 630A<br/>
Soft Starter Panel: Up to 132 KW<br/>
VFD Panel           : Up to 132 KW <br/>
New Tech Power Industries have Type Test certificate for Short Circuit Test from CPRI, Bangalore.<br/>
New Tech Power Industries have Type Test certificate for IP-55 and IP-65 Protection from ERDA, Vadodara.
</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={gwssb_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Gwssb