import React from 'react';
import products_banner from '../img/products_banner.jpg';
import plc_product from '../img/plc_product.jpg';
import {Helmet} from "react-helmet";

function Plc() {
  
  return (
    <>
     <Helmet>
        <title>PLC Control Panel- Best electrical control panel manufacturer in Indore</title>
        <meta name="description" content="New Tech Power Industries Manufacturing and Installation PLC Panel in India, Indore, Madhya Pradesh after understanding the requirement (logic) from customer. It is used for automatic, efficiently and hassle free operation of Plant / Machine. PLC Panel in India are equipped with all necessary switchgear like PLC Modules, HMI Display, Main MCB, SMPS for IO Integration, Relay Cards, Fuse TB etc." />
        <meta name="keywords" content="PLC Control Panel Panel in India, PLC Control Panel Panel in Indore, PLC Control Panel Panel in Madhya Pradesh, PLC Control Panel Panel in Indore, PLC Control Panel Panel manufacturer supplier and distributor in Indore, " />
    </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>PLC Control Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and Installation PLC Panel in India, Indore, Madhya Pradesh after understanding the requirement (logic) from customer. It is used for automatic, efficiently and hassle free operation of Plant / Machine. PLC Panel in India are equipped with all necessary switchgear like PLC Modules, HMI Display, Main MCB, SMPS for IO Integration, Relay Cards, Fuse TB etc. For small application we offer micro PLC with 2 line display. For high end application we provide solution with Expandable PLC with SCADA.<br/> As a special features, New Tech Power Industries provide Redundant type PLC Panel manufacturer &amp; supplier, PLC Panel in MP for critical operation, Ethernet with sim card so that one can change programming of PLC from anywhere in the world through internet connection.</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={plc_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Plc