import React from 'react';
import products_banner from '../img/products_banner.jpg';
import apfc_product from '../img/apfc_product.jpg';
import {Helmet} from "react-helmet";

function Apfc() {
  
  return (
    <>
     <Helmet>
        <title>APFC Control Panel- Best electrical control panel manufacturer in Indore</title>
        <meta name="description" content="New Tech Power Industries Manufacturing and installation  offer APFC Panel in India, Indore, and Madhya Pradesh up to 1000KVA Controlled through Thyristor Cards or Contactor based depends on nature of Load." />
        <meta name="keywords" content="APFC Control Panel Panel in India, APFC Control Panel Panel in Indore, APFC Control Panel Panel in Madhya Pradesh, APFC Control Panel Panel in Indore, APFC Control Panel Panel manufacturer supplier and distributor in Indore, " />
    </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>APFC Control Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and installation  offer APFC Panel in India, Indore, and Madhya Pradesh up to 1000KVA Controlled through Thyristor Cards or Contactor based depends on nature of Load. <br/>To overcome harmonic effects New Tech Power Industries use detuned Reactors. New Tech Power Industries supply the panels with APP / MPP Heavy duty capacitor Banks as per characteristics of electrical loads. New Tech Power Industries use microprocessor based APFC Panel Relay up to 16 stages. As a special features, you can monitor the APFC Panel Relay output on computer. This Panel is of great use to minimize loss and wastage of energy and heavy penalties by electricity boards. Our APFC Panel is fully integrated with suitable APFC relay, switchgears, and capacitor banks to offer a complete solution to effectively eradicate lagging power factor.</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={apfc_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Apfc