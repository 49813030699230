import React from 'react';
import About_banner from '../img/about_banner.jpg';
import about_img from '../img/about_img.jpg';
import line from '../img/line.png';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import brand1 from '../img/deals/1.png';
import brand2 from '../img/deals/2.png';
import brand3 from '../img/deals/3.png';
import brand4 from '../img/deals/4.png';
import brand5 from '../img/deals/5.png';
import brand6 from '../img/deals/6.png';
import brand7 from '../img/deals/7.png';
import brand8 from '../img/deals/8.png';
import brand9 from '../img/deals/9.png';
import brand10 from '../img/deals/10.png';
import brand11 from '../img/deals/11.png';
import brand12 from '../img/deals/12.png';
import brand13 from '../img/deals/13.png';



function About() {
  const state = {
    responsive: {
      0: {
        items: 2,
      },
      450: {
        items: 3,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 6,
      },
    },
  }

  return (
    <>
    
       <Helmet>
        <title>About- New Tech Power Industries</title>
        <meta name="description" content="New Tech Power Industries is successfully working since last 15 years in industrial sector. We are working in manufacturing, supplies and distribution as per requirement of industries." />
        <meta name="keywords" content="Electrical Control Panel in India, Electrical Control Panel in Indore, Electrical Control Panel in Madhya Pradesh, Solar Panel in Indore, Solar Panel in Madhya Pradesh, Electrical Panel, Solar Panel, Fire Safety panel, Manufacturing Company, Manufacturers, Design, Electrical panel in India, Electrical Control Panel ,supplier and distributor in India, Electrical Control Panel manufacturer,supplier and distributor in Indore, Electrical Control Panel manufacturer,supplier and distributor in Madhya Pradesh, Solar Panel manufacturer,supplier and distributor in Indore, Solar Panel manufacturer,supplier and distributor in Madhya Pradesh, Fire Safety panel manufacturer,supplier and distributor in Madhya Pradesh, manufacturer,supplier and distributor in Indore," />
      </Helmet>
      
     
      <div className="Page_banner">
        <img src={About_banner} alt="About_banner" />
      </div>
      {/*---------About Start------- */}
      <div className="container welcome">
        <div className="row">



          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>New Tech Power Industries</h2>
            <p className="para">New Tech Power Industries is successfully working since last 15 years in industrial sector. We are working in
              manufacturing, supplies and distribution as per requirement of industries. We keep in mind
              the requirement of the industry.

              As per the requirement of the industries we authorized the top brands.

              New Tech Power Industries promises to provide the best service for the brand products.Our professionalism & vast experience has enabled us to create a niche for ourselves & make our presence felt worldwide. With a range of Electrical, Automated & Instrument Control Panel Manufacturer, New Tech Power Industries is a one-stop centre, providing diverse solutions to our customers.<br />New Tech Power Industries guarantee you low costs and high quality on all our electrical control panel solutions. With extra focused talent, skills, space, and equipment at its disposal, a company specialising in providing outsourced manufacturing services, for instance, can transport savings in a selection of ways and provide real efficiencies that pointedly impact their customers’ bottom line.</p>
            <Link to="/ppc" className="Button">Products</Link>


          </div>
          <div className="col-md-6 col-12">

            <img src={about_img} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
      {/*---------About End------- */}
      {/*---------Why Us------- */}
      <div className="why_us my-5">
        <div className="container">
          <h2 className="text-center">Why New Tech</h2>
          <div className="linebottom text-center">
            <img src={line} data-no-retina="" alt="" />
          </div>
          <p className="para">The organization has a state-of-the-art manufacturing unit, which is well equipped with all the latest machines and technologies. All the machines are operated by a team of experienced technicians, which has the requisite experience behind them.<br /><br />

            Our manufacturing unit is well integrated with several units such as a fabrication section, an electrical wiring & assembly section every department is equipped with high accuracy tools for Hydraulic Lugging Machine, Wire Stripper, Crimping tool, PVC Channel Cutter, MCB Channel cutter etc. New Tech Power Industries is using ERP software for best utilisation and records for all departments like production, planning, Purchase, Stores, Quality, Customer Support. We use Estimation & Designing Software for compact, precise and best engineering features.</p>

          <div className="row why-list">
            <div className="col-lg-6 col-md-6 col-12">
              <p><i className="icon"></i>Our Quality Control department follows Quality Assurance Plan as per ISO9001:2015.</p>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <p><i className="icon"></i>To measure the steel sheet thickness, we have high accuracy measuring tools like Vernier Callipers and Micrometers</p>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <p><i className="icon"></i>To measure the thickness of Pain/Powdercoating We have Digital Paint Thickness meter.</p>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <p><i className="icon"></i>New Tech Power Industries have Megger, HV Tester, Clamp On Meter, Multimeter, Earth Tester to measure the various electrical parameters.</p>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <p><i className="icon"></i>For electrical load test we have Primary Current Injection Kit up to 1000Amp.</p>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <p><i className="icon"></i>All above measuring instruments are calibrated regularly through NABL certified Agency as per our ISO schedule.</p>
            </div>
          </div>
        </div>
      </div>
      {/*---------Why Us End------- */}
      {/*---------Brands deals in Start------- */}
      <div className="products">
        <div className="container">
          <h2 className="text-center">We Deals in</h2>
          <div className="linebottom text-center">
            <img src={line} data-no-retina="" alt="Line" />
          </div>
          <OwlCarousel className='owl-theme brands' loop margin={10}  items={6} responsive={state.responsive} nav>
            <div className=' item mx-2'>
            <img src={brand1} data-no-retina="" alt="Brand Logo" /> 
            </div>
            <div className='  item mx-2'>
            <img src={brand2} data-no-retina="" alt="Brand Logo" />  
            </div>
            <div className='  item mx-2'>
            <img src={brand3} data-no-retina="" alt="Brand Logo" /> 
            </div>
            <div className=' item mx-2'>
            <img src={brand4} data-no-retina="" alt="Brand Logo" />  
            </div>
            <div className=' item mx-2'>
            <img src={brand5} data-no-retina="" alt="Brand Logo" />  
            </div>
            <div className='item mx-2'>
            <img src={brand6} data-no-retina="" alt="Brand Logo" /> 
            </div>
            <div className=' item mx-2'>
            <img src={brand7} data-no-retina="" alt="Brand Logo" /> 
            </div>
            <div className='  item mx-2'>
            <img src={brand8} data-no-retina="" alt="Brand Logo" />  
            </div>
            <div className='  item mx-2'>
            <img src={brand9} data-no-retina="" alt="Brand Logo" /> 
            </div>
            <div className=' item mx-2'>
            <img src={brand10} data-no-retina="" alt="Brand Logo" />  
            </div>
            <div className=' item mx-2'>
            <img src={brand11} data-no-retina="" alt="Brand Logo" />  
            </div>
            <div className='item mx-2'>
            <img src={brand12} data-no-retina="" alt="Brand Logo" /> 
            </div>
            <div className='item mx-2'>
            <img src={brand13} data-no-retina="" alt="Brand Logo" /> 
            </div>
          </OwlCarousel>
        </div>
      </div>
      {/*---------Brands deals in End------- */}


    </>
  )
}

export default About