import React from 'react';
import {Helmet} from "react-helmet";
import solar_banner from '../img/solar_banner.jpg';
import Solar_Product from '../img/solar.jpg';

function Solar() {
  return (
    <>
     <Helmet>
        <title>Solar Panel- Best Solar panel distributor in Indore</title>
        <meta name="description" content="New Tech Power Industries is an Indian owned leading wholesaler of Solar Products. Focused entirely on the distribution of Residential and Commercial solar photovoltaic products. We deliver quality, reputable solar brands coupled with superior customer services." />
        <meta name="keywords" content="Solar Panel in India, Solar Panel in Indore, Solar Panel in Madhya Pradesh, Solar Panel in Indore, Solar Panel  supplier and distributor in Indore," />
    </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={solar_banner} alt="solar_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>Solar Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries is an Indian owned leading wholesaler of Solar Products. Focused entirely on the distribution of Residential and Commercial solar photovoltaic products. We deliver quality, reputable solar brands coupled with superior customer services. Our customer network is located in almost every state and territory of India. We have wide range of solar panel products to offer to the customers according to their custom requirements.<br/>
            We are official distributor of top solar panel manufacturer brands in india such as  Adani Solar, Vikram Solar limited, Waaree energies Ltd, Renewsys Solar, Tata Power Solar, Swelect Energy System Limited, Emmvee Solar System, Premier Solar, Loom Solar, Saatvik Gren Energy 	


            </p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={Solar_Product} className="img-responsive w-100" alt="Solar Panel" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Solar