import React from 'react'
import products_banner from '../img/products_banner.jpg';
import dg_product from '../img/dg_product.jpg';

function Dg() {
  document.title='DG Synchronizing Panel- Best electrical control panel manufacturer in Indore'
  return (
    <>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>DG Synchronizing Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and installation DG Synchronizing Panel in Indore, Madhya Pradesh is one of our product, which can be used to get Total Power by Synchronize between 2 DGs OR Between Mains and DG. New Tech Power Industries offer Synchronizing Panel with necessary switchgears like ACB / MCCB, Synchronizing Relay, Indicating Meters, Alarm Annunciators, Protection Relays like EF,RP,OL,UV etc., By installing this panel one can get power from Mains and DG or DGs at the same time depends on his load demand.<br/> It will automatically start / Stop DG as per Load demand. As special features, New Tech Power Industries can provide Synchronizing Panel with AMF and PLC so that it can be monitor from Control Room.</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={dg_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Dg