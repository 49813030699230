import React from 'react';
import products_banner from '../img/products_banner.jpg';
import ppc_product from '../img/ppc_product.jpg';
import {Helmet} from "react-helmet";

function Ppc() {
  
  return (
    <>
       <Helmet>
        <title>PCC Control Panel- Best electrical control panel manufacturer in Indore</title>
        <meta name="description" content="New Tech Power Industries Manufacturing and Installations PCC Panel in Indore, M.P, India with a current carrying capacity up to 6300Amp. New Tech Power Industries have Type Test Certificate up to 65KA/ 1sec for Short Circuit test form CPRI." />
        <meta name="keywords" content="PPC Control Panel in India, PPC Control Panel in Indore, PPC Control Panel in Madhya Pradesh, PPC Control Panel in Indore, PPC Control Panel manufacturer supplier and distributor in Indore, " />
    </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>PCC Control Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and Installations PCC Panel in Indore, M.P, India with a current carrying capacity up to 6300Amp. New Tech Power Industries have Type Test Certificate up to 65KA/ 1sec for Short Circuit test form CPRI.<br/> Our Power Control Center (PCC Panel) manufacturers, suppliers integrated with all necessary protections to ensure that it meets all safety standards prevailing in the industry. New Tech Power Industries offer PCC with APFC, PCC with AMF, and PCC Panel Manufacturer as per requirement of customer. In some application, New Tech Power Industries provide flexibility to operate from two-power source or either one. As a special feature, New Tech Power Industries can provide Energy management system through which you can have one-month record of Energy data</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={ppc_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Ppc