import React from 'react';
import products_banner from '../img/products_banner.jpg';
import amf_product from '../img/amf_product.jpg';
import {Helmet} from "react-helmet";

function Amf() {
  
  return (
    <>
       <Helmet>
        <title>AMF Control Panel- Best electrical control panel manufacturer in Indore</title>
        <meta name="description" content="New Tech Power Industries Manufacturing and Installation off MCC Panels in India, Indore, Madhya Pradesh. A device that is used to control and actualize the performance of an electric motor." />
        <meta name="keywords" content="MCC Control Panel in India, MCC Control Panel in Indore, MCC Control Panel in Madhya Pradesh, MCC Control Panel in Indore, MCC Control Panel manufacturer supplier and distributor in Indore," />
    </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>AMF Control Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and installation AMF Control Panel is one of our product, which can be used for automatic change over between Mains and DG supply.<br/> New Tech Power Industries offer AMF Control Panel with necessary switchgears like ACB / MCCB, Contactors, AMF Relay, AC MF Meters, DC Meters, Battery Charges etc and necessary protections. By installing this panel one can get power from DG at the time of failure of Mains and it will shut off the DG when Mains restores. As special features New Tech Power Industries can provide engine safety features like Low Lub Oil, Oil Temp. High, Charge Low etc.</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={amf_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Amf