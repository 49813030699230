import React from 'react'
import products_banner from '../img/products_banner.jpg';
import instrument_product from '../img/instrument_product.jpg';
import {Helmet} from "react-helmet";

function Instrument() {
  
  return (
    <>
       <Helmet>
        <title>Instrumentation Panel- Best electrical control panel manufacturer in Indore</title>
        <meta name="description" content="New Tech Power Industries Manufacturing and installation Instrument Panel all India, Indore, Madhya Pradesh are used to Monitor / Control the operation of Instruments working on Temperature, Level ,Flow, Pressure applications. Temperature application are controlled using PID / Thyristor, SSR for precise control." />
        <meta name="keywords" content="Instrumentation Panel Panel in India, Instrumentation Panel Panel in Indore, Instrumentation Panel Panel in Madhya Pradesh, Instrumentation Panel Panel in Indore, Instrumentation Panel Panel manufacturer supplier and distributor in Indore,  " />
    </Helmet>
      {/*---------Banner Start------- */}
      <div className="Page_banner">
        <img src={products_banner} alt="About_banner" />
      </div>
      {/*---------Banner End------- */}
      <div className="container product_description my-5">
        <div className="row">
          <div className="col-md-6 col-12" style={{ marginBottom: "20px" }}>

            <h2 style={{ color: "#ed3338" }}>Instrumentation Panel</h2>
            <p className="para text-justify" style={{ textAlign: "justify",padding:"0px 20px 0px 0px" }}>New Tech Power Industries Manufacturing and installation  Instrument Panel all  India, Indore, Madhya Pradesh are used to Monitor / Control the operation of Instruments working on Temperature, Level ,Flow, Pressure applications. Temperature application are controlled using PID / Thyristor, SSR for precise control.<br/> Flow can be controlled using Flow meter / Control Valves, for pressure, New Tech Power Industries use various types of Pressure Switches. Instrument Panel displays all values like PH, ORP, Temperature, Flow at one place so that one can monitor and control it easily. New Tech Power Industries can provide Annunciator with Hooter so that operator has instant idea about any abnormal conditions and it can be controlled immediately.</p>
           


          </div>
          <div className="col-md-6 col-12">

            <img src={instrument_product} className="img-responsive w-100" alt="Electrical panel manufacturer" />
          </div>



        </div>
      </div>
    </>
  )
}

export default Instrument