import React from 'react'
import client_banner from '../img/client_banner.jpg';
import client1 from '../img/clients/client1.png';
import client2 from '../img/clients/client2.png';
import client3 from '../img/clients/client3.png';
import client4 from '../img/clients/client4.png';
import client5 from '../img/clients/client5.png';
import client6 from '../img/clients/client6.png';
import client7 from '../img/clients/client7.png';
import client8 from '../img/clients/client8.png';
import client9 from '../img/clients/client9.png';
import client10 from '../img/clients/client10.png';
import client11 from '../img/clients/client11.png';
import client12 from '../img/clients/client12.png';
import client13 from '../img/clients/client13.png';
import client14 from '../img/clients/client14.png';
import client15 from '../img/clients/client15.png';
import client16 from '../img/clients/client16.png';
import client17 from '../img/clients/client17.png';
import client18 from '../img/clients/client18.png';
import client19 from '../img/clients/client19.png';
import client20 from '../img/clients/client20.png';
import client21 from '../img/clients/client21.png';
import client22 from '../img/clients/client22.png';
import client23 from '../img/clients/client23.png';
import client24 from '../img/clients/client24.png';




function Clients() {
  document.title = 'Clients- New Tech Power Industries'
  return (
    <>
      <div className="Page_banner">
        <img src={client_banner} alt="Client_banner" />
      </div>
      {/*---------Client-list Start------- */}
      <div className="container my-5">
        <div className="row gallery">
          <div className="col-md-2 col-4">
            <img src={client1} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client2} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client3} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client4} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client5} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client6} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client7} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client8} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client9} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client10} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client11} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client12} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client13} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client14} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client15} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client16} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client17} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client18} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client19} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client20} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client21} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client22} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client23} alt="Client Logo" />
          </div>
          <div className="col-md-2 col-4">
            <img src={client24} alt="Client Logo" />
          </div>
          
        </div>
      </div>
      {/*---------Client-list End------- */}
    </>

  )
}

export default Clients