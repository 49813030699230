import React from 'react';
import { FaCheckSquare, FaWhatsapp, FaWhatsappSquare } from 'react-icons/fa';
import {Link } from "react-router-dom";
import call from '../img/call.png';

function Footer() {
    return (
        <>
        
            <div className="Footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <h5 className="about-left">About New Tech Power Industries</h5>
                            <div className="row">
                                <div className=" Footer_links col-md-6 col-6">
                                    <ul>
                                        <li><FaCheckSquare/><Link to="/">Home</Link></li>
                                        <li><FaCheckSquare/><Link to="/clients">Our Clients</Link></li>
                                        <li><FaCheckSquare/><Link to="/contact">Contact Us</Link></li>
                                    </ul>
                                </div>
                                <div className=" Footer_links col-md-6 col-6">
                                    <ul>
                                        <li><FaCheckSquare/><Link to="/about">About Us</Link></li>
                                        <li><FaCheckSquare/><Link to="/certificates">Certificates</Link></li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <h5 className="about-left">About New Tech Power Industries</h5>
                            <div className="row">
                                <div className=" Footer_links col-md-6 col-6">
                                    <ul>
                                        <li><FaCheckSquare/><Link to="/ppc">PCC Panel</Link></li>
                                        <li><FaCheckSquare/><Link to="/apfc">APFC Panel</Link></li>
                                        <li><FaCheckSquare/><Link to="/vfd">VFD Control Panel</Link></li>
                                        <li><FaCheckSquare/><Link to="/instrument">Instrumentation Panel</Link></li>
                                        <li><FaCheckSquare/><Link to="/amf">AMF Control Panel</Link></li>
                                        <li><FaCheckSquare/><Link to="/bus">Bus Duct</Link></li>
                                    </ul>
                                </div>
                                <div className=" Footer_links col-md-6 col-6">
                                    <ul>
                                    <li><FaCheckSquare/><Link to="/mcc">MCC Panel</Link></li>
                                        <li><FaCheckSquare/><Link to="/power">Power Distribution Panel</Link></li>
                                        <li><FaCheckSquare/><Link to="/plc">PLC Control Panel</Link></li>
                                        <li><FaCheckSquare/><Link to="/sov">SOV Panel</Link></li>
                                        <li><FaCheckSquare/><Link to="/dg">DG Synchronization Panel</Link></li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <h5 className="about-left">Contact Us</h5>
                            <div className="footer_contact">
                                <p><strong>Address:</strong><br/>27/2, Manoramaganj, Indore (M.P) 452001</p>
                            </div>
                            <div className="footer_contact">
                                <p><strong>Phone:</strong><br/>9301012346, 8815984527</p>
                            </div>
                            <div className="footer_contact">
                                <p><strong>Email:</strong><br/>newtechpowerindustries@gmail.com</p>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
            <div className="copyright">
                    <p>{(new Date().getFullYear())} © Copyright New Tech Power Indutries.All Rights Reserved. Developed By <a href="https://app.myinfoline.in/" target="_blank">Myinfoline.Inc</a></p>
                </div>
                <a href="https://api.whatsapp.com/send?phone=919301012346&text=Enquiry Related your Products." className="float" target="_blank">
<FaWhatsapp/>
</a>
<div className="pop animate bounce "><a href="tel:919301012346" onClick="return gtag_report_conversion('tel:919301012346')"> <img src={call}/> </a> </div>
        </>
    )
}

export default Footer